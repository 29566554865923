import React from "react";
import { PageHeader } from "antd";

export default function Header() {
  return (
    <a href="/" >
      <PageHeader
        title="💰 Build Guild Support"
        subTitle={(
          <div>
            round 1 - <a target="_blank" href="https://etherscan.io/address/0x01Cf46E060a2476C36A661E5F8773e2DD2BBAC42#code">mainnet contract</a>
          </div>
        )}
      />
    </a>
  );
}
